import React, { Component } from 'react'

import api from '../../services';

import Pagination from "react-pagination-js";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Tabs from '../../components/Tabs';
import Header from '../../includes/Header';
import Sidemenu from '../../includes/Sidemenu';
import Loading from '../../components/Loading';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

toast.configure();

export default class AnoProva extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visibleModal: false,
            isLoading: false,
            tooltip2: '',
            tabs: ['Edições', 'Questões'],
            
            searchText: '',
            filteredData: null,
            regperpage: 10,
            totalReg: 0,
            totalPages: 1,
            regini: 0,
            regfim: 0,
            currentPage: 1,
            data: [],

            cd_anoprova: '',
            cd_prova: '',
            ds_anoprova: '',
            ds_prova: '',
            ds_anoprova_origem: '',
        };
    }

    handleInputChange = (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
            [name]: value
        });
    }

    componentDidMount() {
        const params = this.props.match.params;
        const prova = JSON.parse(localStorage.getItem('ds_prova'));

        if (params.id !== undefined) {
            this.setState({
                cd_prova: params.id,
                ds_prova: prova
            }, () => {
                this.consultaAPI();
            });
        } else if (params.id_anoprova !== undefined) {
            this.setState({
                cd_prova: params.id,
                cd_anoprova: params.id_anoprova
            }, () => {
                this.consultaAPI();
            });
        } else {
            this.consultaAPI();
        }
    }

    consultaAPI = async () => {
        try {
            this.setState({ isLoading: true });

            const response = await api.get(`/anoprova/?cd_prova=${this.state.cd_prova}`);
            const dados = response.data.data;

            this.setState({
                isLoading: false,
                data: dados,
                totalReg: dados.length
            }, () => {
            });
        } catch (response) {
            console.log(response)
            this.setState({ isLoading: false, tooltip2: "Houve um erro" });
        }
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        this.setState({
            isLoading: true,
        });

        try {
            const response = this.state.cd_anoprova != ''
                            ? await api.put(`/anoprova/${this.state.cd_anoprova}`, this.state)
                            : await api.post(`/anoprova/`, this.state);

            if (response.data.errors === "") {
                toast.success(
                    `Edição ${this.state.cd_anoprova != '' ? 'editada' : 'inserida'} com sucesso!`,
                    { autoClose: 3000, toastId: 1 }
                );

                this.consultaAPI();
                this.setState({ isLoading: false, ds_anoprova: '', cd_anoprova: '', ds_anoprova_origem: '' });
            } else {
                toast.error(response.data.errors, { autoClose: 3000, toastId: 1 });
            }

        } catch (resp) {
            console.log(resp)
            this.setState({ isLoading: false, tooltip2: "Houve um erro" });
        }
    }

    selectTab = (index) => {
        switch (index) {
            case 0: this.props.history.push(`/prova/anoprova/${this.state.cd_prova}`); break;
            case 1: this.props.history.push(`/prova/questoes/${this.state.cd_prova}`); break;
        }
    }

    voltar = () => {
        this.props.history.push('/prova');
    }

    changeNovoAnoProva = () => {
        this.setState({
            cd_prova: '',
            cd_anoprova: '',
            ds_prova: '',
        });

        this.props.history.push(`/prova/form/`)
    }

    changeCurrentPage = numPage => {
        this.setState({ currentPage: numPage });
    };

    selectItem = async (item) => {
        this.props.history.push(`/prova/anoprova/${item.cd_prova}`);

        try {
            const response = await api.get(`/anoprova/${item.cd_anoprova}`);
            const dados = response.data.data;
    
            this.setState({
                cd_anoprova: dados.cd_anoprova,
                ds_anoprova: dados.ds_anoprova,
                ds_anoprova_origem: dados.ds_anoprova_origem
            });
        } catch (error) {
            console.log(error)
            this.setState({ isLoading: false, tooltip2: "Houve um erro"});            
        }
    }

    confirmRemoveItem = ({ cd_anoprova }) => {
        this.setState({ 
            visibleModal: !this.state.visibleModal,
            itemDelValue: cd_anoprova, 
        });
    }

    removeItem = async (id) => {
        try { 
            const response = await api.delete(`/anoprova/${id}`);

            if (response.data.errors === "") {
                this.consultaAPI();
            } else {
                this.setState({ isLoading: false, tooltip2: "Dificuldades Técnicas.."});
            }

            this.setState({ visibleModal: false })
        } catch (response) {
            console.log(response)
            this.setState({ isLoading: false, tooltip2: "Houve um erro"});
        }
    }

    toggleModal = () => {
        this.setState({ visibleModal: !this.state.visibleModal })
    } 

    renderItem = (item) => {
        return (
            <tr key={item.cd_anoprova}>
                <td className="tdClickable" onClick={() => this.confirmRemoveItem(item)}>
                    <div className="deletar"><i className="mdi mdi-24px mdi-delete-circle" style={{color:'#f2545b'}}></i></div>
                </td>
                <td className="tdClickable" onClick={() => this.selectItem(item)}>
                    <i className="mdi mdi-24px mdi-pencil-circle"></i>
                </td>
                
                <td className="tdColumn">{ item.ds_anoprova }</td>
                <td className="tdColumn">{ item.ds_anoprova_origem }</td>
            </tr>
        )
    }

    renderItems = () => {
        const { data, filteredData, currentPage, regperpage } = this.state;
        
        let regfim = currentPage * regperpage;
        let regini = regfim - regperpage;
        
        if (data.length > 0) {
            const dados = (filteredData && filteredData.length > 0) ? filteredData : data;
            return dados.map((item, index) => {
                if (index >= regini && index < regfim)
                    return this.renderItem(item);
            })
        } else {
            return (
                <tr className='text-center'>
                    <td colSpan="3">Nenhum registro encontrado</td>
                </tr>
            )
        }
    }

    clearState = () => {
        this.setState({
            cd_anoprova: '',
            ds_anoprova: '',
            ds_anoprova_origem: '',
        });
    }

    render() {
        const { isLoading, tabs, visibleModal, itemDelValue, data } = this.state;

        return (
            <div style={{ width: '100%' }}>
                <Sidemenu />
                <Loading isLoading={isLoading} />
                <main className="admin-main">
                    <Header />
                    <div className="bg-gray-400">
                        <div className="container m-b-30">
                            <div className="row">
                                <div className="col-12 p-t-20 p-b-70">
                                    <div className="media">
                                        <div className="avatar avatar mr-3">
                                            <div className="avatar-title bg-gray-600 rounded-circle icon-placeholder mdi mdi-account-group"></div>
                                        </div>
                                        <div className="media-body">
                                            <h2>{this.state.cd_prova === '' ? 'Nova Prova' : `Prova ${this.state.ds_prova && '- ' + this.state.ds_prova}`}</h2>
                                        </div>

                                        <div className="float-right">
                                            <Button onClick={() => this.changeNovoAnoProva()} className="btn-primary btn btn-md m-b-15 ml-2 mr-2">
                                                <i className="mdi mdi-plus"></i> Nova Prova
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container  pull-up">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        {this.state.cd_prova > 0 &&
                                            <Tabs
                                                fnCallback={this.selectTab}
                                                selected={0}
                                                campos={tabs}
                                            />
                                        }
                                        <form onSubmit={this.handleSubmit} className="formGeral m-t-30">
                                            <div className="form-row" >
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="ds_anoprova">Edição:</label>
                                                    <input
                                                        type="text"
                                                        name="ds_anoprova"
                                                        autoComplete='off'
                                                        className="form-control"
                                                        value={this.state.ds_anoprova}
                                                        onChange={(e) => this.handleInputChange(e, true)}
                                                    />
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="ds_anoprova_origem">Origem/Referência:</label>
                                                    <textarea 
                                                        name="ds_anoprova_origem" 
                                                        className="form-control" 
                                                        style={{ height: 80 }}
                                                        value={this.state.ds_anoprova_origem}
                                                        onChange={this.handleInputChange} 
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group float-left">
                                                <div onClick={this.voltar} className="btn-outline-primary btn" style={{ cursor: 'pointer' }}>
                                                    Voltar
                                                </div>
                                                <div onClick={this.clearState} className="btn-primary btn m-l-10 " style={{ cursor: 'pointer' }}>
                                                    Novo
                                                </div>
                                            </div>
                                            <div className="form-group float-right">
                                                <button type="submit" className="btn btn-primary">Salvar</button>
                                            </div>
                                        </form>
                                        <div className="table-responsive m-t-20">
                                            <Modal isOpen={visibleModal} toggle={this.toggleModal}>
                                                <ModalHeader toggle={this.toggleModal}>Confirmação</ModalHeader>
                                                <ModalBody>
                                                Tem certeza que deseja excluir esta edição?
                                                </ModalBody>
                                                <ModalFooter>
                                                    <Button color="danger" onClick={() => this.removeItem(itemDelValue)}>Sim, com certeza</Button>
                                                    <Button color="primary" onClick={this.toggleModal}>Não</Button>
                                                </ModalFooter>
                                            </Modal>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: 10 }}></th>
                                                        <th style={{ width: 10 }}></th>
                                                        <th style={{ textAlign: data.length > 0 ? '' : 'center' }}>Edição</th>
                                                        <th>Origem/Referência</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.renderItems()}
                                                </tbody>
                                            </table>
                                            <Pagination
                                                currentPage={this.state.currentPage}
                                                totalPages={this.state.totalReg}
                                                totalSize={this.state.totalReg}
                                                sizePerPage={this.state.regperpage}
                                                changeCurrentPage={this.changeCurrentPage}
                                                theme="bootstrap"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}